<template>
  <div>
    <Header />
    <div class="container mx-auto border border-grey-2 rounded-lg my-10">
      <div class="flex flex-col p-4 w-full">
        <div class="flex font-bold px-2">
          <label class="block font-bold align-middle my-auto">Location :</label>
          <div class="bg-primary hover:bg-primary text-white font-bold py-1 px-4 border border-primary rounded-xl ml-2">
            {{ selectedPaket.nama_kota }}
          </div>
        </div>
        <div class="py-4 px-2">
          <div class="flex sm:flex-row flex-col justify-between w-full">
            <span class="font-extrabold text-xl md:text-3xl text-primary px-2">
              {{ selectedPaket.nama }}
            </span>
            <div
              class="flex flex-row sm:justify-between justify-end text-lg font-bold gap-2 items-center text-primary sm:mt-0 mt-3 sm:mr-0 mr-2">
              <div class="sm:col-span-2">
                <!-- <button
                  class="bg-primary rounded-full font-xl h-10 w-10 grid grid-cols-1 content-center items-center justify-items-center"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-message"
                    :style="{ color: 'white' }"
                  />
                </button> -->
              </div>
              <div class="sm:col-span-3">
                <button
                  class="bg-yellow text-black w-28 sm:w-40 font-extrabold py-2 px-4 rounded-md font-xl"
                  @click="setPackage">
                  BOOK
                </button>
              </div>
            </div>
          </div>
          <div class="md:grid md:grid-cols-5 gap-2 px-2 overflow-auto">
            <div class="col-span-2 py-2">
              <div class="mb-2">
                <video
                  :key="selectedPaket.id"
                  :poster="selectedPaket.galeri[0].foto"
                  controls
                  controlsList="nodownload"
                  class="object-cover rounded-2xl max-h-64 w-full">
                  <source :src="selectedPaket.video" type="video/mp4" />
                </video>
              </div>
              <div class="flex flex-col gap-4 text-left text-primary">
                <div>
                  <div class="flex flex-row justify-start text-lg font-bold gap-2 items-center">
                    <font-awesome-icon icon="fa-regular fa-clock" :style="{ color: 'black', fontSize: '24px' }" />
                    &nbsp; {{ selectedPaket.durasi }} {{ selectedPaket.satuan }}

                    <div class="flex items-center">
                      <img
                        v-if="selectedPaket.level === '3'"
                        src="@/assets/icons/ic-level-advance.svg"
                        class="w-20"
                        :title="selectedPaket.level"
                        :alt="selectedPaket.level" />
                      <img
                        v-else-if="selectedPaket.level === '2'"
                        src="@/assets/icons/ic-level-intermediate.svg"
                        class="w-20"
                        :title="selectedPaket.level"
                        :alt="selectedPaket.level" />
                      <img
                        v-else
                        src="@/assets/icons/ic-level-beginner.svg"
                        class="w-20"
                        :title="selectedPaket.level"
                        :alt="selectedPaket.level" />
                    </div>
                    <span>
                      {{
                        selectedPaket.level === "3"
                          ? "Advance"
                          : selectedPaket.level === "2"
                          ? "Intermediate"
                          : selectedPaket.level === "1" && "Beginner"
                      }}
                    </span>
                  </div>
                </div>
                <div :key="selectedPaket.id">
                  Start From :
                  <span class="font-extrabold">{{ hargaMinDetailPaket | toCurrency }}</span>
                </div>
              </div>
            </div>
            <div class="col-span-3 flex-col gap-1 text-sm text-left py-2">
              <div class="mb-4">
                <ul
                  class="flex flex-row md:gap-2 rounded-lg sm:flex text-center font-bold text-xs md:text-sm"
                  id="detailTab"
                  data-tabs-toggle="#detailTabContent"
                  role="tablist">
                  <li
                    class="w-full cursor-pointer shadow border border-gray-500 rounded-tl-lg rounded-tr-lg"
                    role="presentation"
                    v-on:click.prevent.stop="activeTab = 0"
                    :class="{
                      'bg-white': activeTab == 0,
                      'bg-grey-1': activeTab != 0,
                    }">
                    <a
                      v-on:click.prevent.stop="activeTab = 0"
                      class="inline-block py-2 px-2 text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 active"
                      >Descriptions</a
                    >
                  </li>
                  <li
                    class="w-full cursor-pointer shadow border border-gray-500 rounded-tl-lg rounded-tr-lg"
                    role="presentation"
                    v-on:click.prevent.stop="activeTab = 1"
                    :class="{
                      'bg-white': activeTab == 1,
                      'bg-grey-1': activeTab != 1,
                    }">
                    <a
                      v-on:click.prevent.stop="activeTab = 1"
                      class="inline-block py-2 px-2 text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50"
                      >Itinerary</a
                    >
                  </li>
                  <li
                    class="w-full cursor-pointer shadow border border-gray-500 rounded-tl-lg rounded-tr-lg"
                    role="presentation"
                    v-on:click.prevent.stop="activeTab = 2"
                    :class="{
                      'bg-white': activeTab == 2,
                      'bg-grey-1': activeTab != 2,
                    }">
                    <a
                      v-on:click.prevent.stop="activeTab = 2"
                      class="inline-block py-2 px-2 text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                      >Location</a
                    >
                  </li>
                  <li
                    class="w-full cursor-pointer shadow border border-gray-500 rounded-tl-lg rounded-tr-lg"
                    role="presentation"
                    v-on:click.prevent.stop="activeTab = 3"
                    :class="{
                      'bg-white': activeTab == 3,
                      'bg-grey-1': activeTab != 3,
                    }">
                    <a
                      v-on:click.prevent.stop="activeTab = 3"
                      class="inline-block py-2 px-2 text-center text-gray-500 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300"
                      >Review({{ selectedPaket.review_total }})</a
                    >
                  </li>
                </ul>
              </div>
              <div id="detailTabContent">
                <div
                  class="p-8 bg-gray-50 rounded-lg dark:bg-gray-800 h-80 overflow-y-auto"
                  :class="{ hidden: activeTab != 0 }"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab">
                  <p class="text-sm text-gray-500 dark:text-gray-400" v-html="nl2br(selectedPaket.long_desc)"></p>
                  <div v-if="selectedPaket.galeri.length > 0">
                    <carousel
                      :perPage="2"
                      :scrollPerPage="true"
                      :navigationEnabled="true"
                      :autoplay="false"
                      :paginationEnabled="false">
                      <slide v-for="(slider, slideridx) in selectedPaket.galeri" :key="slideridx">
                        <div
                          v-if="slider.hapus == '0'"
                          class="max-w-xs rounded-md overflow-hidden shadow-lg hover:scale-105 transition duration-500 cursor-pointer m-5">
                          <div>
                            <img :src="slider.foto" :alt="slider.nama" class="object-cover h-40 w-full" />
                          </div>
                        </div>
                      </slide>
                    </carousel>
                  </div>
                  <div v-if="selectedPaket.luggage != ''">
                    <h1 class="font-medium leading-tight text-2xl mt-0 mb-2">Facilities</h1>
                    <p class="text-sm text-gray-500 dark:text-gray-400" v-html="nl2br(selectedPaket.luggage)"></p>
                  </div>
                  <div class="bg-gray-200 rounded-xl shadow-xl grid grid-cols-2 gap-2 p-2">
                    <ul class="list-disc pl-6">
                      <li v-if="selectedPaket.adv_book != ''">
                        <span class="font-bold">Booking In Advance</span>
                        <p>Must be book at least {{ selectedPaket.adv_book }} the trip</p>
                      </li>
                      <li>
                        <span class="font-bold">Minimum Age</span>
                        <p>{{ selectedPaket.min_age }} years old</p>
                      </li>
                      <li>
                        <span class="font-bold">Operational Days</span>
                        <div class="grid grid-cols-3 gap-2">
                          <div class="flex flex-col gap">
                            <span>Sun</span>
                            <span>Mon</span>
                            <span>Tue</span>
                          </div>
                          <div class="flex flex-col gap">
                            <span>Wed</span>
                            <span>Thu</span>
                            <span>Fri</span>
                          </div>
                          <div class="flex flex-col gap">
                            <span>Sat</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul class="list-disc pl-6">
                      <li>
                        <span class="font-bold">Operational Time</span>
                        <p>09:00 - 17:00</p>
                      </li>
                      <li>
                        <span class="font-bold">Categories</span>
                        <p v-html="selectedPaket.category"></p>
                      </li>
                      <li>
                        <span class="font-bold">Guide Language</span>
                        <p>{{ selectedPaket.guide }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                  :class="{ hidden: activeTab != 1 }"
                  id="dashboard"
                  role="tabpanel"
                  aria-labelledby="dashboard-tab">
                  <p class="text-sm text-gray-500 dark:text-gray-400" v-html="nl2br(selectedPaket.itinerary)"></p>
                </div>
                <div
                  class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800 h-80 w-full overflow-auto"
                  :class="{ hidden: activeTab != 2 }"
                  id="settings"
                  role="tabpanel"
                  aria-labelledby="settings-tab">
                  <p>
                    {{ selectedPaket.meeting_point }} -
                    {{ selectedPaket.meeting_address }}
                  </p>
                  <GmapMap
                    :center="{
                      lat: parseFloat(selectedPaket.meeting_latitude),
                      lng: parseFloat(selectedPaket.meeting_longitude),
                    }"
                    :zoom="16"
                    map-type-id="terrain"
                    class="md:h-80 h-56">
                    <GmapMarker
                      :position="
                        google &&
                        new google.maps.LatLng(
                          parseFloat(selectedPaket.meeting_latitude),
                          parseFloat(selectedPaket.meeting_longitude)
                        )
                      "
                      :clickable="true"
                      :draggable="true" />
                  </GmapMap>
                </div>
                <div
                  class="rounded-lg flex flex-col gap-4 p-2 h-60 overflow-y-auto"
                  :class="{ hidden: activeTab != 3 }"
                  id="contacts"
                  role="tabpanel"
                  aria-labelledby="contacts-tab"
                  v-if="selectedPaket.review_total > 0">
                  <div
                    class="flex flex-col gap-4 shadow-lg rounded-3xl border border-gray-100 p-4 text-base"
                    v-for="(rev, revidx) in selectedPaket.reviews"
                    :key="revidx">
                    <div class="flex flex-row justify-between gap-2">
                      <div class="grid grid-cols-2 gap-2 justify-between font-bold items-center">
                        <span
                          ><star-rating
                            :rating="parseFloat(rev.bintang)"
                            :increment="0.01"
                            :show-rating="false"
                            :star-size="25"
                            :read-only="true"></star-rating
                        ></span>
                        <span
                          >By <span class="text-primary">{{ rev.namacustomer }}</span></span
                        >
                      </div>
                      <span>{{ dateText(rev.tgl) }}</span>
                    </div>
                    <div class="flex">{{ rev.desc }}</div>
                    <div class="flex flex-row border-t-2 border-gray-300 justify-between pt-2">
                      <span class="cursor-pointer">
                        <font-awesome-icon icon="fa-regular fa-thumbs-up" class="text-base" />
                        <span v-if="rev.terbantu > 0">{{ rev.terbantu }} users helped</span><span v-else>Helped?</span>
                      </span>
                      <span class="cursor-pointer">
                        <font-awesome-icon icon="fa-solid fa-comments" class="text-base" />
                        Discussion
                      </span>
                      <span class="cursor-pointer" @click="handleReply(rev)">
                        More Reply
                        <font-awesome-icon icon="fa-solid fa-angle-down" class="text-base" />
                      </span>
                    </div>
                    <div
                      class="flex flex-col gap-2 border-t-2 border-gray-300 py-2"
                      v-if="showReply && listReply.length > 0 && rev.id == listReply[0].idh">
                      <div class="font-bold">
                        <span
                          >By <span class="text-primary">{{ listReply[0].namacustomer }}</span></span
                        >
                      </div>
                      <div class="flex flex-row gap-2 items-center">
                        <span class="bg-primary rounded-md text-white p-2">Seller</span>
                        <span>{{ dateText(listReply[0].tgl) }}</span>
                      </div>
                      <div class="flex">
                        <span>{{ listReply[0].desc }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="rounded-lg grid font-bold md:text-xl text-sm text-center"
                  :class="{ hidden: activeTab != 3 }"
                  id="contacts"
                  role="tabpanel"
                  aria-labelledby="contacts-tab">
                  No review available.<br />Be the first to review this trip!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import CardPaket from "../components/CardPaket";
import Vue from "vue";
import { gmapApi } from "vue2-google-maps";
import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import CarouselSlider from "../components/CarouselSlider";
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "PackageDetail",
  components: {
    Header,
    Footer,
    CardPaket,
    Carousel,
    Slide,
    LMap,
    LTileLayer,
    LMarker,
  },
  data: () => ({
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    mapOptions: {
      zoomSnap: 0.5,
    },
    apiTripwe: process.env.VUE_APP_TRIPWE_PUBLIC_API,
    apiSaebo: process.env.VUE_APP_SAEBO_PUBLIC_API,
    activeTab: 0,
    idWilayah: "",
    selectedPaket: {},
    listReview: [],
    listReply: [],
    markers: [],
    showReply: false,
  }),
  created() {
    this.loadPackage();
    this.idWilayah = this.$route.params?.pack?.wilayah_toko ?? "";
  },
  computed: {
    google: gmapApi,
    totalReview() {
      return this.listReview.length;
    },
    hargaMinDetailPaket() {
      let harga = 0;
      if (this.selectedPaket != null && this.selectedPaket?.detail != null && this.selectedPaket?.detail.length > 1) {
        const det = this.selectedPaket;
        let lowes = null;
        for (const key in det.detail) {
          if (Object.hasOwnProperty.call(det.detail, key)) {
            const element = det.detail[key];
            if (lowes == null || (lowes != null && lowes > element.harga)) {
              lowes = element.harga;
            }
          }
        }
        harga = lowes;
      } else if (this.selectedPaket?.detail.length == 1) {
        harga = this.selectedPaket.detail[0]["harga"];
      }
      return harga;
    },
    textLabel() {
      let str = "";
      switch (this.$route.params.slug) {
        case "wateradventure":
          str = "Watersport";
          break;
        case "landadventure":
          str = "Land Adventure";
          break;
        case "skyadventure":
          str = "Sky Adventure";
          break;

        default:
          str = "Watersport";
          break;
      }
      return str;
    },
    iconTrip() {
      let def = require("@/assets/icons/icon-water-advanture.svg");
      switch (this.$route.params.slug) {
        case "wateradventure":
          def = require("@/assets/icons/icon-water-advanture.svg");
          break;
        case "landadventure":
          def = require("@/assets/icons/icon-land-advanture.svg");
          break;
        case "skyadventure":
          def = require("@/assets/icons/icon-sky-advanture.svg");
          break;
        default:
          def = require("@/assets/icons/icon-water-advanture.svg");
          break;
      }
      return def;
    },
  },
  methods: {
    async loadPackage() {
      try {
        this.$store.commit("setOverlayLoading", true);
        const res = await this.$http.post(`${this.$apiTripweWeb}/package/search/all`, {
          slug: this.$route.params.slug,
        });
        if(res.data.status != 200){
          throw res.data.message
        }
        this.selectedPaket = res.data?.data ?? {};
        return;
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    dateText(str) {
      var a = this.$moment();
      var b = this.$moment(str);
      var c = a.diff(b, "days");
      if (c < 7) {
        return c + " Days Ago";
      } else if (c >= 7 && c <= 28) {
        c = a.diff(b, "weeks");
        return c + " Weeks Ago";
      } else {
        return b.format("YYYY-MM-DD HH:mm:ss");
      }
    },
    setPackage() {
      this.$store.commit("setPackage", this.selectedPaket);
      if (this.selectedPaket.tipe == "0") {
        this.$router.push({
          name: "CheckoutPaket",
          params: { slug: "wateradventure" },
        });
      } else if (this.selectedPaket.tipe == "1") {
        this.$router.push({
          name: "CheckoutPaket",
          params: { slug: "landadventure", trip: "offroad" },
        });
      } else if (this.selectedPaket.tipe == "2") {
        this.$router.push({
          name: "CheckoutPaket",
          params: { slug: "landadventure", trip: "offroad" },
        });
      } else if (this.selectedPaket.tipe == "4") {
        this.$router.push({
          name: "CheckoutPaket",
          params: { slug: "skyadventure" },
        });
      }
    },
    handleRedirect(trip) {
      this.$router.push({
        name: "DetailPaket",
        params: { slug: this.$route.params.slug, trip: trip },
      });
      this.loadPaket();
    },
    handleReply(detail) {
      this.showReply = !this.showReply;
      if (this.showReply == true) {
        this.loadReply(detail);
      }
    },
    loadReview() {
      var kode = "2";
      if (this.$route.params.slug == "wateradventure") {
        kode = "2";
      } else if (this.$route.params.slug == "landadventure") {
        if (this.$route.params.trip == "offroad") {
          kode = "3";
        } else if (this.$route.params.trip == "onroad") {
          kode = "4";
        }
      } else if (this.$route.params.slug == "skyadventure") {
        kode = "9";
      }
      Vue.axios
        .post("https://tripwe.id/apptripwe/review/getreviewh", {
          paket: this.selectedPaket.id,
          jenis: kode,
        })
        .then((response) => {
          this.listReview = response.data?.data ?? [];
          return;
        });
    },
    loadTotalReply(detail) {
      var kode = "2";
      if (this.$route.params.slug == "wateradventure") {
        kode = "2";
      } else if (this.$route.params.slug == "landadventure") {
        if (this.$route.params.trip == "offroad") {
          kode = "3";
        } else if (this.$route.params.trip == "onroad") {
          kode = "4";
        }
      } else if (this.$route.params.slug == "skyadventure") {
        kode = "9";
      }
      Vue.axios
        .post("https://tripwe.id/apptripwe/review/getreviewe", {
          idh: detail.id,
          jenis: kode,
        })
        .then((response) => {
          this.listReview = response.data?.data ?? [];
          return;
        });
    },
    loadReply(detail) {
      var kode = "2";
      if (this.$route.params.slug == "wateradventure") {
        kode = "2";
      } else if (this.$route.params.slug == "landadventure") {
        if (this.$route.params.trip == "offroad") {
          kode = "3";
        } else if (this.$route.params.trip == "onroad") {
          kode = "4";
        }
      } else if (this.$route.params.slug == "skyadventure") {
        kode = "9";
      }
      Vue.axios
        .post("https://tripwe.id/apptripwe/review/getreviewd", {
          idh: detail.id,
          jenis: kode,
        })
        .then((response) => {
          this.listReply = response.data?.data ?? [];
          return;
        });
    },
    nl2br(str, is_xhtml) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }
      var breakTag = is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
      return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
    },
  },
};
</script>
