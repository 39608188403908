var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full bg-black bg-opacity-50 relative"},[_c('div',{staticClass:"absolute h-full w-full",style:(_vm.sliderStyle)}),_c('div',{staticClass:"container py-8 item-center text-center w-full mx-auto"},[(_vm.sliders.length > 0)?_c('VueSlickCarousel',_vm._b({ref:"carousel",on:{"afterChange":_vm.handleAfterChange}},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.sliders),function(slider){return _c('div',{key:slider.id,staticClass:"overflow-hidden mx-4 cursor-pointer",on:{"click":function($event){return _vm.handleOpenSlider(slider)}}},[_c('div',{staticClass:"h-52 w-80 shadow-lg rounded-md mx-auto",style:({
            backgroundImage: 'url(' + slider.link_foto + ')',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backdropFilter: 'blur(10px)',
          })})])}),0):_vm._e()],1),_c('detail-slider',{ref:"detailVoucher"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }