<template>
  <!-- <div class="w-full bg-primary p-4">
      <carousel :perPage="3" :scrollPerPage="false" :navigationEnabled="false" :autoplay="false" :paginationEnabled="false">
        <slide v-for="slider in sliders" :key="slider.judul">
          <div>
            <img class="object-contain w-full h-72" :src="slider.link_foto">
          </div>
        </slide>
      </carousel>
  </div> -->

  <div class="w-full bg-black bg-opacity-50 relative">
    <div class="absolute h-full w-full" :style="sliderStyle"></div>
    <div class="container py-8 item-center text-center w-full mx-auto">
      <VueSlickCarousel
        v-bind="settings"
        ref="carousel"
        @afterChange="handleAfterChange"
        v-if="sliders.length > 0"
      >
        <div
          v-for="slider in sliders"
          :key="slider.id"
          class="overflow-hidden mx-4 cursor-pointer"
          @click="handleOpenSlider(slider)"
        >
          <div
            class="h-52 w-80 shadow-lg rounded-md mx-auto"
            :style="{
              backgroundImage: 'url(' + slider.link_foto + ')',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              backdropFilter: 'blur(10px)',
            }"
          ></div>
        </div>
      </VueSlickCarousel>
    </div>
    <detail-slider ref="detailVoucher"></detail-slider>
  </div>
</template>

<script>
import Vue from "vue";
import { Carousel, Slide } from "vue-carousel";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "CarouselSlider",
  components: {
    Carousel,
    Slide,
    VueSlickCarousel,
    DetailSlider: () => import("@/components/layout/DetailSlider.vue"),
  },
  data: () => ({
    sliders: [],
    selectedSlider: {},
    settings: {
      arrows: false,
      dots: true,
      infinite: false,
      slidesToShow: 1,
      centerMode: true,
      lazyLoad: "progressive",
      centerPadding: "100px",
      variableWidth: true,
      focusOnSelect: true,
      swipeToSlide: true,
    },
  }),
  methods: {
    handleAfterChange(slideIndex) {
      this.selectedSlider = this.sliders[slideIndex];
    },
    handleOpenSlider(slider) {
      this.$refs.detailVoucher.open({ data: slider });
    },
  },
  computed: {
    sliderStyle() {
      return {
        backgroundImage: "url(" + this.selectedSlider.link_foto + ")",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        filter: "blur(6px)",
        opacity: 0.5,
      };
    },
  },
  async mounted() {
    this.$store.commit("setOverlayLoading", true);
    try {
      const res = await this.$http.get(
        `${this.$apiTripweWeb}/promo/list`
      );
      if (res.data.status) {
        this.sliders = res.data.data;
        if (this.sliders.length > 0) {
          this.selectedSlider = this.sliders[0];
          if (this.sliders.length == 1) {
            this.settings.slidesToShow = 3;
          } else {
            this.settings.slidesToShow = 1;
          }
        } else {
          this.selectedSlider = {};
        }
      }
    } catch (error) {
    } finally {
      this.$store.commit("setOverlayLoading", false);
    }
  },
};
</script>
